export interface UserEmailLoginArgs {
  email: string;
  password: string;
}

export interface User {
  id: string;
  email: string;
}

export interface UserAuth {
  type: string;
}

export async function getAuthUserInfo() {
  const response = await fetch(
    `${import.meta.env.VITE_API_V2_BASE_URL}/api/v2/auth/userinfo`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    },
  );

  return await response.json();
}
